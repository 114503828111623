<template>
  <div>
    <v-layout wrap justify-center>
      <v-flex xs11 sm11 pt-5>
        <v-layout wrap>
          <v-flex xs6 sm6 md8 text-left>
            <span
              style="
                color: #1f1f1f;
                font-family: poppinssemibold;
                font-size: 18px;
              "
              >My orders</span
            >
          </v-flex>
          <!-- <v-flex xs6 sm6 md4 text-right>
            <v-text-field
              solo
              dense
              flat
              prepend-inner-icon="mdi-magnify"
              background-color="#F5F5F5"
              color="#787878"
              placeholder="Search orders"
            >
            </v-text-field>
          </v-flex> -->
        </v-layout>
        <v-layout wrap v-if="orders.length > 0" justify-center py-2>
          <v-flex xs12 py-4 v-for="(list, l) in orders" :key="l">
            <v-card>
              <v-layout wrap justify-start py-3 px-5>
                <v-flex xs12 py-2>
                  <v-layout wrap justify-space-between>
                    <v-flex xs12 sm4 md4 text-left>
                      <v-layout wrap>
                        <v-flex xs12>
                          <span
                            style="color: #7e8f85; font-family: poppinsregular"
                            >Order Date
                          </span>
                        </v-flex>
                        <v-flex xs12>
                          <span
                            style="color: #000000; font-family: poppinsmedium"
                            >{{ formatDate(list.create_date) }}
                          </span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs12 sm4 md4 lg4>
                      <v-layout wrap>
                        <v-flex xs12>
                          <span
                            style="color: #7e8f85; font-family: poppinsregular"
                            >Order Number
                          </span>
                        </v-flex>
                        <v-flex xs12 v-if="list.orderId">
                          <span
                            style="color: #000000; font-family: poppinsmedium"
                            >{{ list.orderId }}
                          </span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs12 sm4 lg4 md4 text-right>
                      <v-layout wrap justify-end>
                        <v-flex xs12>
                          <span
                            style="color: #7e8f85; font-family: poppinsregular"
                            >Status
                          </span>
                        </v-flex>
                        <v-flex xs12 v-if="list.orderStatus=='Active'">
                          <span
                            style="color: #30b868; font-family: poppinsmedium"
                            >Order Placed</span
                          >
                        </v-flex>
                        <v-flex xs12 v-else>
                          <span
                            style="color: #30b868; font-family: poppinsmedium"
                            >{{ list.orderStatus }}</span
                          >
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs12 py-4>
                  <v-divider></v-divider>
                </v-flex>
                <v-flex xs12 py-2 v-for="(item, i) in list.items" :key="i">
                  <v-layout wrap>
                    <v-flex xs3 sm3 md2 v-if="item.productId">
                      <v-img
                        :src="mediaUURL + item.productId.photos[0]"
                        height="100%"
                        width="auto"
                        contain
                        :alt="item.name"
                      >
                        <template v-slot:placeholder>
                          <ImageLoader />
                        </template>
                      </v-img>
                    </v-flex>
                    <v-flex xs9>
                      <v-layout wrap justify-center fill-height>
                        <v-flex xs12 sm12 md12 align-self-center pl-2 text-left>
                          <v-layout
                            wrap
                            justify-start
                            style="line-height: 16px"
                            fill-height
                            v-if="item.productId"
                          >
                            <v-flex xs12>
                              <router-link
                                :to="'/productDetails?id=' + item.itemId._id"
                              >
                                <span
                                  style="
                                    font-family: poppinssemibold;
                                    font-size: 15px;
                                    color: #191919;
                                  "
                                >
                                  {{ item.productId.productname }}
                                  <!-- <span v-if="list.name.length > 25"> ... </span> -->
                                </span>
                              </router-link>
                            </v-flex>
                            <v-flex xs12 pt-3 v-if="item.itemId.size">
                              <span
                                style="
                                  color: #191919;
                                  font-size: 13px;
                                  font-family: poppinsmedium;
                                "
                                >Size
                                <span style="font-family: poppinsregular"
                                  >: {{ item.itemId.size }}</span
                                >
                              </span>
                            </v-flex>
                            <v-flex xs12 pt-1>
                              <span
                                style="
                                  color: #191919;
                                  font-size: 13px;
                                  font-family: poppinsmedium;
                                "
                                >OTY
                                <span style="font-family: poppinsregular"
                                  >: {{ item.quantity }}
                                </span>
                              </span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <!-- <v-flex xs12 sm6 md6>
                        <v-layout wrap justify-end pt-3>
                            <v-flex xs12 align-self-end text-right>
                            <span
                              style="color: #30b868; font-family: poppinssemibold"
                              >Order Placed</span
                            >
                          </v-flex>
                          <v-flex xs12 align-self-end text-right pt-10>
                            <span
                              style="color: #383838; font-family: poppinssemibold"
                              >{{ list.offerprice }}</span
                            ><br>
                            <span style="color: #000000; font-size: 12px;font-family: poppinsregular;">(inclusive of all taxes)</span>
                          </v-flex>
                        </v-layout>
                      </v-flex> -->
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs12 py-6>
                  <v-divider></v-divider>
                </v-flex>
                <v-flex xs12 sm6 text-left>
                  <v-layout wrap>
                    <v-flex xs12>
                      <v-btn
                        dark
                        color="#30b868"
                        :to="'/Profile/orderDetails?id=' + list._id"
                        ><span
                          style="font-family: opensanssemibold; font-size: 13px"
                          >VIEW ORDER</span
                        ></v-btn
                      >
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs12 sm6 text-right>
                  <v-layout wrap justify-end>
                    <v-flex xs12 pb-3 v-if="list.isPromoCodeApplied">
                      <span
                        style="
                          color: #3d3b3b;
                          font-family: poppinsregular;
                          font-size: 14px;
                        "
                        >Promo Code Applied :</span
                      >
                      <span style="color: #3d3b3b; font-family: poppinsregular"
                        >₹ {{ list.couponDiscount }}</span
                      >
                    </v-flex>
                    <v-flex xs12 pb-4>
                      <span
                        style="
                          color: #3d3b3b;
                          font-family: poppinsregular;
                          font-size: 14px;
                        "
                        >Total Amount :
                      </span>
                      <span style="color: #383838; font-family: poppinsmedium"
                        >₹ {{ list.subTotal }}</span
                      >
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
        <v-flex xs12 sm12 v-else align-self-center pt-5>
          <span
            style="
              font-family: poppinsregular;
              font-size: 25px;
              color: #000000;
              cursor: pointer;
            "
            >Oops! No Order Found</span
          >
        </v-flex>
        <v-layout wrap justify-center pt-2>
          <v-flex xs12>
            <div class="text-center pb-5" v-if="pages > 1">
              <v-pagination
                :length="pages"
                :total-visible="7"
                v-model="currentPage"
                color="#30B868"
                circle
              ></v-pagination>
            </div>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      products: [],
      page: 1,
      currentPage: 1,
      pages: 0,
      limit: 5,
      cart: {
        cart: [],
      },
      searchKey: null,
      pincode: null,
      items: ["1", "2", "3", "4"],
      lists: [
        {
          image: require("./../../assets/images/product5.png"),
          category: "Personal Care",
          name: "Softening Creamy Soap Bar",
          quantity: "100 Gm",
          offerprice: "₹ 120",
          price: "(₹ 180)",
        },
      ],
      orders: [],
    };
  },
  watch: {
    currentPage() {
      this.getData();
    },
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/purchaseList",
        params: {
          limit: this.limit,
          page: this.currentPage,
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.orders = response.data.data;
          this.pages = Math.ceil(response.data.totalLength / this.limit);
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      // var hours = dt.getHours();
      // var minutes = dt.getMinutes();
      dt = dt.toString();
      // var ampm = hours >= 12 ? "pm" : "am";
      // hours = hours % 12;
      // hours = hours ? hours : 12;
      // minutes = minutes < 10 ? "0" + minutes : minutes;
      // day = day < 10 ? "0" + day : day;
      var strTime = day + " " + dt.slice(4, 7) + " " + year;
      return strTime;
    },
  },
};
</script>